import React from 'react';
import logoRicardo from './imagenes/logoRicardo.jpg'
import './index.css'

function BottomBanner()  {
    return (
        <div className='bottomBanner'>
        <div className='bottomSuperior'>
            <div className='superiorIzq'>
            <a href="https://e00-elmundo.uecdn.es/assets/multimedia/imagenes/2024/05/07/17150731876265.jpg" target="_blank"><img src={logoRicardo} alt='logo'></img>
            </a>
            <a href='tel:+34633610776'>633-610-776</a>
            <a href='mailto:aegarcia5b@gmail.com'>aegarcia5b@gmail.com</a>
            </div>

            <div className='superiorDerecha'>
                <div>
                    <h2>Hours</h2>
                    <span>November 1st - February 1st Monday, Tuesday, Thursday, Friday & Saturday: 9AM-4PM Closed Wednesday & Sunday</span>
                </div>
                <br></br>
                <div>
                    <h2>Location</h2>
                    <span>calle 1234</span>
                </div>
                <button>hola</button>
            </div>
        </div>

        {/* <div className='bottomInferior'>hola</div> */}
        </div>
        );
    }


export default BottomBanner;
