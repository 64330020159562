import React, { useRef, useEffect, useState } from "react";
import { nextImage, prevImage, updateCarousel } from "./carouselLogic";
import "./carrusel.css";

const Carousel = () => {
  const carouselRef = useRef(null);
  const [index, setIndex] = useState(0);
//   const totalImages = 5;
  const maxMoves = 3;
  const intervalTime = 7000;

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => nextImage(prev, maxMoves));
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateCarousel(carouselRef, index);
  }, [index]);

  return (
    <div>
      <div className="carousel-container">
        <div className="carousel" ref={carouselRef}>
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 1" />
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 2" />
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 3" />
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 4" />
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 5" />
          <img src="https://es.onlyfresh.com/cdn/shop/articles/dog-g6aa17498a_1920.jpg?v=1659454762&width=1100" alt="Imagen 6" />
          {/* <img src="imagen5.jpg" alt="Imagen 5" /> */}
        </div>
      </div>
      <div className="caja1">
      <button onClick={() => setIndex((prev) => prevImage(prev, maxMoves))}>
        ←
      </button>
      <button onClick={() => setIndex((prev) => nextImage(prev, maxMoves))}>
        →
      </button>
      </div>

    </div>
  );
};



export default Carousel;
